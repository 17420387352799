import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—May 2010" />
	<NewsHeader />
    <h2>May 2010</h2>



	<h3 className="blogdate">
	Thursday, May 27, 2010
	</h3>
	<h4 className="blogitemtitle">
	Recent Developments - two shows in June
	</h4>
	   <div className="blogitembody">
	<p>This should be neat-o... <i>Jesse Rivest and the Recent Developments</i>
	have two shows coming up in June! The Recent Developments are:</p>
	<ul>
	<li>
	Brendan Schenk on banjo, accordion, and mandolin
	</li>
	<li>
	Peter Cogswell on wire brushes, kick-box, shakers and tambourines
	</li>
	</ul>
	<p>
	The shows are part of showcase evenings in Wellington, New Zealand:
	</p>
	<ul>
	<li>
	probably 8:00pm on June 3 @ <b>Mighty Mighty</b> on Cuba Street (The
	Wanted Sessions evening)
	</li>
	<li>
	likely 7:00pm on June 11 @ <b>Mojo Invincible</b> on Willis Street
	(Mojo Acoustic evening)
	</li>
	</ul>
	<p>
	Should be fun, hope to see you there! Keep an eye on Facebook or Twitter
	for any updates.
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 13:00 +1200</p>
	   </div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
